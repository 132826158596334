export function createdBlock(start, end, resourceId = null, addClass = []) {
  const classNames = ['blockEvent', 'blockEventResource'];

  if(addClass)
    classNames.concat(addClass);

  const block = {
    start: start,
    end: end,
    appointment: { status: "BLOCKED" },
    display: 'background',
    classNames: classNames,
    type: 'block',
    allDay: false,
    ...(resourceId && { resourceId })
  };

  return block;
}